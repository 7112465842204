angular.module('cpformplastApp')
  .controller('LocationAddModalController', function ($scope, DataManager, Notification, $modalInstance, location) {

    $scope.location = location;
    $scope.jobs = [];
    $scope.showNumpad = false;
    $scope.currentTab = 'content';
    $scope.showAlert = true;

    $scope.rawMaterials = [];
    $scope.rawMaterial = {
      quantity:0,
      category: {},
      locationId:$scope.location._id
    };
    $scope.rawMaterialSubmitted = false;

    $scope.finishedProducts = [];
    $scope.finishedProduct = {
      quantity:0,
      locationId:$scope.location._id
    };
    $scope.finishedProductSubmitted = false;
    $scope.modificationDone = false;
    $scope.isValidJob = true;

    $scope.categories = [];
    $scope.uniqueCategories = [];
    $scope.categoriesDimensions = {};


    $scope.init = function() {

      if (!$scope.location) {
        Notification.error('Un problème est survenu');
      }

      var i;
      for (i=0 ; i<$scope.location.rawMaterials.length ; i++) {
        $scope.rawMaterials.push($scope.location.rawMaterials[i]);
      }
      for (i=0 ; i<$scope.location.finishedProducts.length ; i++) {
        $scope.finishedProducts.push($scope.location.finishedProducts[i]);
      }

      DataManager.fetchJobs().then((data) => {
        $scope.jobs = data;
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu lors du chargement des jobs');
      });

      DataManager.fetchCategories(true).then(data => {
        $scope.categories = data;
        $scope.filterUnique();
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu lors du chargement des categories');
      })
    };

    $scope.$watch('finishedProduct.job', function() {
      if ($scope.finishedProduct.job && $scope.finishedProduct.job.item) {
          $scope.finishedProduct.quantity = $scope.finishedProduct.job.item.quantityPerBox * $scope.finishedProduct.job.item.quantityPerSkid;
        }
    });

    $scope.update_job = function(){
      $scope.setModificationDone();
    };

    $scope.$watch('rawMaterial.job', function() {

      try{
        $scope.rawMaterial.job.item.category.name;
      }catch(err){
        return;
      }

      $scope.rawMaterial.category.name = $scope.rawMaterial.job.item.category.name;
      $scope.rawMaterial.category.dimension = $scope.rawMaterial.job.item.category.dimension;
    });


    $scope.setCurrentTab = function(tab) {
      if (!$scope.isTabAvailable(tab)) {
        return;
      }
      $scope.currentTab = tab;
    };

    $scope.isTabAvailable = function(tab) {

      if (tab === 'content') {
        return true;
      } else if (tab === 'raw-material') {
        return $scope.location.finishedProducts.length===0;
      } else if (tab === 'finished-product') {
        return $scope.location.rawMaterials.length===0;
      }
      return false;
    };

    $scope.hideAlert = function() {
      $scope.showAlert = false;
    };

    $scope.toggleNumpad = function() {
      $scope.showNumpad = !$scope.showNumpad;
    };

    $scope.setModificationDone = function() {
      $scope.modificationDone = true;
    };

    $scope.ModificationVerfication = function(rawMaterial) {
      if (!rawMaterial.job){
        return;
      }

      if (rawMaterial.category.dimension === rawMaterial.job.item.category.dimension
        && rawMaterial.category.name === rawMaterial.job.item.category.name) {
          $scope.isValidJob = true;
          $scope.setModificationDone();
      } else {
        $scope.modificationDone = false;
        $scope.isValidJob = false;
        Notification.warning('Les spécifications de la matière première ne conrespondent pas a celles de la job demandé.');
      }
    };

    $scope.filterJob = function (rawMaterial) {
      return function(job) {
        return (job.item.categoryId === rawMaterial.categoryId);
      }
    };

    $scope.onFocus = function (e) {
        $(e.target).trigger('input');
        $(e.target).trigger('change'); // for IE
    };

    $scope.submitRawMaterial = function() {
      $scope.rawMaterialSubmitted = true;
      if ($scope.rawMaterial.quantity <= 0 || !$scope.rawMaterial.category.name) {
        return false;
      }

      $scope.getCategoryId();
      $scope.rawMaterials.push($scope.rawMaterial);

      $scope.rawMaterialSubmitted = false;
      $scope.rawMaterial = {
        quantity:1,
        locationId:$scope.location._id,
        category: {}
      };

      $scope.currentTab = 'content';
      $scope.modificationDone = true;
    };

    $scope.submitFinishedProduct = function() {
      $scope.finishedProductSubmitted = true;
      if (!$scope.finishedProduct.job) {
        return false;
      }
      if (!$scope.finishedProduct.job._id) {
        return false;
      }
      if ($scope.finishedProduct.quantity <= 0) {
        return false;
      }

      $scope.finishedProducts.push($scope.finishedProduct);
      $scope.finishedProductSubmitted = false;
      $scope.finishedProduct = {
        quantity:1,
        location:$scope.location._id
      };
      $scope.currentTab = 'content';
      $scope.modificationDone = true;
    };

    $scope.submitLocation = function() {
      if(!$scope.modificationDone) {
        return;
      }

      var i;
      for (i=0 ; i<$scope.rawMaterials.length ; i++) {
        $scope.saveRawMaterial($scope.rawMaterials[i]);
      }
      for (i=0 ; i<$scope.finishedProducts.length ; i++) {
        $scope.saveFinishedProduct($scope.finishedProducts[i]);
      }
    };

    $scope.saveRawMaterial = function(rawMaterial) {
      rawMaterial.saved = false;
      rawMaterial.deleted = false;

      var promise ;
      if (!rawMaterial._id && !rawMaterial.toDelete) {
        promise = DataManager.createRawMaterial(rawMaterial);
      } else if (rawMaterial._id && !rawMaterial.toDelete) {
        promise = DataManager.updateRawMaterial(rawMaterial);
      } else if (rawMaterial._id && rawMaterial.toDelete) {
        promise = DataManager.deleteRawMaterial(rawMaterial);
      } else {
        rawMaterial.deleted = true;
        $scope.detectSaveCompletion();
        return;
      }

      promise.then((data) => {
        if (data) {
          rawMaterial._id = data._id;
          rawMaterial.saved = true;
        } else {
          rawMaterial.deleted = true;
        }
        $scope.detectSaveCompletion();
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu');
      });

    };

    $scope.saveFinishedProduct = function(finishedProduct) {
      if ($scope.suborder) {
        finishedProduct._id = null;
        finishedProduct.toDelete = true;
      }
      finishedProduct.saved = false;
      finishedProduct.deleted = false;

      var promise ;
      if (!finishedProduct._id && !finishedProduct.toDelete) {
        promise = DataManager.createFinishedProduct(finishedProduct);
      } else if (finishedProduct._id && !finishedProduct.toDelete) {
        promise = DataManager.updateFinishedProduct(finishedProduct);
      } else if (finishedProduct._id && finishedProduct.toDelete) {
        promise = DataManager.deleteFinishedProduct(finishedProduct);
      } else {
        finishedProduct.deleted = true;
        $scope.detectSaveCompletion();
        return;
      }

      promise.then((data) => {
        if (data) {
          finishedProduct._id = data._id;
          finishedProduct.saved = true;
        } else {
          finishedProduct.deleted = true;
        }
        $scope.detectSaveCompletion();
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu');
      });

    };

    $scope.detectSaveCompletion = function() {
      var updatedRawMaterials = [];
      var updatedFinishedProduct = [];
      var i;

      for (i=0 ; i<$scope.rawMaterials.length ; i++) {
        if (!$scope.rawMaterials[i].saved && !$scope.rawMaterials[i].deleted) {
          return false;
        }

        if (!$scope.rawMaterials[i].deleted) {
          updatedRawMaterials.push($scope.rawMaterials[i]);
        }
      }
      for (i=0 ; i<$scope.finishedProducts.length ; i++) {
        if (!$scope.finishedProducts[i].saved && !$scope.finishedProducts[i].deleted) {
          return false;
        }

        if (!$scope.finishedProducts[i].deleted) {
          updatedFinishedProduct.push($scope.finishedProducts[i]);
        }
      }

      $scope.rawMaterials = updatedRawMaterials;
      $scope.finishedProducts = updatedFinishedProduct;
      $scope.location.rawMaterials = updatedRawMaterials;
      $scope.location.finishedProducts = updatedFinishedProduct;

      Notification.success("L'empalcement <strong>" + $scope.location.name + '</strong> fut enregistré');

      $scope.$dismiss();
    };

    //Nouvelle fonction rajoutée pour la gestion des catégories
    $scope.update_select = function(){
      $scope.rawMaterial.category.dimension = $scope.categoriesDimensions[$scope.rawMaterial.category.name][0];
    };

    $scope.filterUnique = function(){

      $scope.categories.forEach(function(category){

        if(typeof $scope.categoriesDimensions[category.name] == "undefined"){
          $scope.uniqueCategories.push(category);
          $scope.categoriesDimensions[category.name] = [];
          $scope.categoriesDimensions[category.name].push(category.dimension);
        }else{
          $scope.categoriesDimensions[category.name].push(category.dimension);
        }

      });
    };

    $scope.getCategoryId = function(){

      $scope.categories.forEach(function(category){
        if(category.name == $scope.rawMaterial.category.name && category.dimension == $scope.rawMaterial.category.dimension){
          $scope.rawMaterial.category._id = category._id;
        }
      });

    };


    $scope.init();
});
